<template>
  <div>
    <!-- 顶部 -->
    <div class="header">
      <div class="open flex jcl aic">
        <!-- <i :class="flot==false?'el-icon-caret-left':'el-icon-caret-right'" @click="changeMenu"></i> -->
        <i class="w35" v-show="flot == false"
          ><img src="@/assets/img/ck.png" @click="changeMenu(true)" alt=""
        /></i>
        <i class="w35" v-show="flot == true"
          ><img src="@/assets/img/sx.png" @click="changeMenu(false)" alt=""
        /></i>
      </div>
      <div class="headerLeft ">
        <div class="ml20 mr20">
          <a href="https://oss-sz.yoyodeal.cn/file/import/operating_manual.docx" class="f14 reb">
            后台操作手册
          </a>
           
        </div>
        <div class="ml20 mr20">
          <a href="https://oss-sz.yoyodeal.cn/file/import/APP_Operation_Manual.docx" class="f14 reb">
            APP操作手册
          </a>
           
        </div>
        <div class="ml20 mr20">
          <a href="https://oss-sz.yoyodeal.cn/file/import/PC_Operation_Manual.docx.docx" class="f14 reb">
            国内PC操作手册
          </a>
           
        </div>
        <div class="tips" @click="goto('needCenter')">
          <el-badge :value="totalNum">
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="26" height="26" fill="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.5411 18.3185L18.665 17.1139C18.4272 16.7869 18.3083 16.3886 18.328 15.9848L18.5633 11.1616C18.7184 7.98168 16.182 5.31854 12.9983 5.31854C9.81459 5.31854 7.27819 7.98169 7.43331 11.1616L7.66858 15.9848C7.68828 16.3886 7.56939 16.7869 7.33163 17.1139L6.4555 18.3185H19.5411ZM12.9983 4.13672C9.13928 4.13672 6.06487 7.36476 6.25289 11.2192L6.48817 16.0424C6.49474 16.177 6.45511 16.3098 6.37585 16.4187L4.8172 18.5619C4.53315 18.9525 4.81215 19.5004 5.29509 19.5004H20.7015C21.1845 19.5004 21.4635 18.9525 21.1794 18.5619L19.6208 16.4187C19.5415 16.3098 19.5019 16.177 19.5085 16.0424L19.7437 11.2192C19.9318 7.36476 16.8573 4.13672 12.9983 4.13672Z"
                fill="#8088E1"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.0014 3.5451C12.0224 3.5451 11.2287 4.33878 11.2287 5.31783H10.0469C10.0469 3.68608 11.3697 2.36328 13.0014 2.36328C14.6332 2.36328 15.956 3.68608 15.956 5.31783H14.7741C14.7741 4.33878 13.9805 3.5451 13.0014 3.5451Z"
                fill="#8088E1"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.9986 22.4549C13.9776 22.4549 14.7713 21.6612 14.7713 20.6822C14.7713 20.3558 15.0359 20.0913 15.3622 20.0913C15.6886 20.0913 15.9531 20.3558 15.9531 20.6822C15.9531 22.3139 14.6303 23.6367 12.9986 23.6367C11.3668 23.6367 10.044 22.3139 10.044 20.6822C10.044 20.3558 10.3086 20.0913 10.6349 20.0913C10.9613 20.0913 11.2259 20.3558 11.2259 20.6822C11.2259 21.6612 12.0195 22.4549 12.9986 22.4549Z"
                fill="#8088E1"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.4029 7.00742C12.449 7.33049 12.2245 7.6298 11.9015 7.67595C11.3443 7.75555 10.2229 8.2522 10.0392 9.53819C9.99308 9.86126 9.69377 10.0857 9.3707 10.0396C9.04763 9.99344 8.82314 9.69412 8.8693 9.37105C9.15831 7.34795 10.9127 6.62339 11.7343 6.50601C12.0574 6.45986 12.3567 6.68435 12.4029 7.00742Z"
                fill="#F13A44"
                fill-opacity="0.4"
              />
            </svg>
          </el-badge>
        </div>
    
        <div class="headerImg">
          <img :src="avatar" alt="" />
        </div>
        <div class="useName">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ username }}
              <i class="el-icon-arrow-down el-icon--right fw800"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="DialogFlag=true"
                >资料编辑</el-dropdown-item
              >
              <el-dropdown-item @click.native="loginOut"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!-- <div
        class="fiex"
        draggable="true"
        :style="{ left: x + 'px', top: y + 'px' }"
        @mousedown.stop.prevent="startDrag"
        @mousemove="handleDrag"
        @mouseup.stop.prevent="stopDrag"
      >
        <span class="Rai cp" @click="tag"
          ><img src="@/assets/img/ai.png" alt="" />
        </span>
      </div> -->
      <AIp v-show="isShow"
        ><div class="w600 pr animate__zoomIn">
          <div>
            <!-- <iframe
              class="w100"
              src="http://chat.halapala.com/"
              frameborder="0"
            ></iframe> -->
          </div>
          <div class="pa tp1" @click="close">
            <i class="el-icon-close f18 cp"></i>
          </div></div
      ></AIp>
    </div>
    <!-- 内容 -->
    <router-view></router-view>
    <!-- 个人资料编辑 -->
    <personalInfoEdit :DialogFlag.sync="DialogFlag" :adminInfo="adminInfo"/>
  </div>
</template>

<script>
import personalInfoEdit from './personalInfoEdit.vue'
export default {
  components: {
    personalInfoEdit
  },
  data() {
    return {
      flot: false,
      totalNum: 3,
      avatar:'',
      username: "",
      isShow: false,
      DialogFlag: false,
      x: 0,
      y: 0,
      isDragging: false,
      offsetX: 0,
      offsetY: 0,
      diss: false,
      firstTime: "", // mousedown的时间戳
      lastTime: "", // mouseup的时间戳
      adminInfo:{}
    };
  },
  created() {
    this.ToDoCenter();
  },
  mounted() {
    const username = localStorage.getItem("username");
    if (username) {
      this.username = username; // 使用存储的数据进行操作
    }
    const avatar = localStorage.getItem("avatar");
    if (avatar) {
      this.avatar = avatar; // 使用存储的数据进行操作
    }
    this.$root.$on("refreshTotalNum", () => {
      const avatar = localStorage.getItem("avatar");
      if (avatar) {
        this.avatar = avatar; // 使用存储的数据进行操作
      }
      // console.log("触发了");
      this.ToDoCenter(); // 调用待办中心方法来刷新页面
    });
  
    this.x = document.body.clientWidth * 0.9;
    this.y = document.body.clientHeight * 0.5;
  },
  methods: {
    async ToDoCenter() {
      const form = new FormData();
      await this.$axios.get(this.webAPI + "/ToDoCenter/index", form).then((res) => {
        // console.log(res.data.total,'数据');
        // 请求成功，处理响应数据
        this.totalNum = res.data.total;
        this.adminInfo = res.data.admin;
      });
    },
    //通用跳转
    goto(text) {
      this.$router.push({
        name: text,
      });
    },
    changeMenu(v) {
      this.$emit("changeShow");
      this.flot = v;
    },
    //退出登录
    loginOut() {
      this.$store.dispatch('LogOut')
      this.$router.push("/login");
    },
    close() {
      this.isShow = false;
    },
    tag() {
      if (this.diss) {
        this.isShow = !this.isShow;
      }
    },
    startDrag(event) {
      this.isDragging = true;
      this.diss = false;
      this.firstTime = new Date().getTime();
      this.offsetX = event.clientX - this.x;
      this.offsetY = event.clientY - this.y;
    },
    handleDrag(event) {
      if (this.isDragging) {
        this.x = event.clientX - this.offsetX;
        this.y = event.clientY - this.offsetY;
      }
    },
    stopDrag() {
      this.lastTime = new Date().getTime();
      if (this.lastTime - this.firstTime < 200) {
        this.diss = true;
      } else {
        this.diss = false;
      }
      this.isDragging = false;
    },
  },
};
</script>
<style scoped lang="less">
.header {
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  .headerLeft {
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .tips {
      margin-right: 24px;
      cursor: pointer;
    }
    .headerImg {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      margin-left: 20px;
      margin-right: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .useName {
      cursor: pointer;
    }
  }
  .el-dropdown-link {
    font-size: 16px;
    color: #240465cc;
    padding-right: 16px;
  }
}
.fw800 {
  font-size: 16px;
  font-weight: 800;
}
.w35 {
  width: 34px;
  height: 34px;
  padding-left: 24px;
  display: inline-block;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
  }
}
.w600 {
  width: 1000px;
  height: 800px;
  background: #fff;
  .w100 {
    width: 100%;
    height: 780px;
  }
}
.fiex {
  position: fixed;
  // right: 5%;
  // bottom: 10%;
  z-index: 49;
}
.Rai {
  display: inline-block;
  width: 80px;
  height: 80px;
  z-index: 100;
  img {
    width: 100%;
    height: 100%;
    transition: all 0.5s;
  }
}
.Rai:hover {
  display: inline-block;
  width: 100px;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
    transform: scale(1.5);
  }
}
.tp1 {
  top: -10px;
  right: -10px;
}
.reb{
  color: #f01c1c;
}
</style>
